<template>
    <div :class="['wit-radio']" :data-selected="internalValue" :id="id">
        <b-form-radio
            v-for="radio in options"
            :key="radio.value"
            @input="emitInput"
            v-model="internalValue"
            :value="radio.value"
            inline
            >{{ radio.label }}</b-form-radio
        >
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        this.internalValue = this.value
    },
    data() {
        return {
            internalValue: null,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style>
.wit-radio {
    margin-top: 5px;
}
</style>
